<template>
  <!-- eslint-disable -->
  <decorator-icon v-bind="attrs">
    <path
        d="M16.0011 19.0907C15.4105 19.0907 14.9317 19.5695 14.9317 20.1601V20.794C14.9317 21.3846 15.4105 21.8634 16.0011 21.8634H24.953C25.5436 21.8634 26.0224 21.3846 26.0224 20.794V20.1601C26.0224 19.5695 25.5436 19.0907 24.953 19.0907H16.0011Z"
        fill="inherit"/>
    <path clip-rule="evenodd"
          d="M20.477 32.9541C22.5218 32.9541 24.4516 32.4622 26.1547 31.5904L33.4412 38.8769C34.9416 40.3773 37.3743 40.3773 38.8747 38.8769C40.3751 37.3765 40.3751 34.9438 38.8747 33.4434L31.5889 26.1576C32.4617 24.4538 32.9541 22.5229 32.9541 20.477C32.9541 13.5862 27.3679 8 20.477 8C13.5862 8 8 13.5862 8 20.477C8 27.3679 13.5862 32.9541 20.477 32.9541ZM20.477 30.8153C26.1867 30.8153 30.8153 26.1867 30.8153 20.477C30.8153 14.7674 26.1867 10.1388 20.477 10.1388C14.7674 10.1388 10.1388 14.7674 10.1388 20.477C10.1388 26.1867 14.7674 30.8153 20.477 30.8153ZM34.9535 37.3645L28.0119 30.4229C28.9204 29.7336 29.7315 28.9227 30.421 28.0144L37.3624 34.9557C38.0276 35.6209 38.0276 36.6993 37.3624 37.3645C36.6972 38.0297 35.6187 38.0297 34.9535 37.3645Z"
          fill="inherit"
          fill-rule="evenodd"/>
  </decorator-icon>
</template>

<script>
import DecoratorIcon from '../decorator/dp-icon-decorator'
import MixinIcon from '../mixin/dp-icon-mixin'

/**
 * @group Components-icon-svg
 * Standard components
 */
export default {
  name: 'dp-zoom-out-icon',
  mixins: [MixinIcon],
  components: {
    DecoratorIcon
  },
  data() {
    return {
      viewBox: '0 0 48 48'
    }
  }
}
</script>
